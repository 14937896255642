import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import Slider from 'react-slick';
import MediaQuery from 'react-responsive';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Hod from '../src/components/Hod/Hod';
import TitleH3 from '../src/components/Titles/TitleH3/TitleH3';
import Layout from '../src/components/Layout/Layout';
import SEO from '../src/helpers/seo';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import tt from '../src/helpers/translation';
import Icon from '../src/helpers/icon';
import pawSliderIcons from '../src/components/PawPanel/paw-slider-icons';

import './partners.scss';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query partners($locale: String!) {
    allPartnersSeoBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allPartnersImageHeaderBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allPartnersImageMobileHeaderBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allPartnersTitleHeaderBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allPartnersBreadcrumb(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allPartnersImages(filter: { lang: { eq: $locale } }, sort: { fields: position, order: ASC }) {
      edges {
        node {
          link
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allColaboratorsImages(
      filter: { lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          link
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }

    allPartnersRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

// allColaboratorsSingleImage(filter: { lang: { eq: $locale } }) {
//   edges {
//     node {
//       link
//       localImage {
//         childImageSharp {
//           fluid(quality: 100) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//       image_alt
//     }
//   }
// }

class Partners extends React.Component {
  state = {};

  render() {
    const settingsUltraLow = {
      centerMode: false,
      infinite: false,
      centerPadding: '60px',
      slidesToShow: 1,
      speed: 500,
      dots: true,
      dotsClass: 'dots-class',
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };

    function SampleNextArrow(props) {
      const { className, style, onClick } = props; //eslint-disable-line
      return (
        <div
          className={className}
          style={{
            ...style,
            display: 'block',
            height: '40px',
            width: '40px',
            borderRadius: '50%',
            zIndex: '100',
            right: '20px',
            paddingLeft: '5px',
          }}
          onClick={onClick}
        >
          <Icon
            icon="chevron-right"
            width="30"
            height="30"
            class="icon-arrow"
            iconSet={pawSliderIcons}
          />
        </div>
      );
    }

    function SamplePrevArrow(props) {
      const { className, style, onClick } = props; //eslint-disable-line
      return (
        <div
          className={className}
          style={{
            ...style,
            display: 'block',
            height: '40px',
            width: '40px',
            borderRadius: '50%',
            zIndex: '100',
            left: '20px',
          }}
          onClick={onClick}
        >
          <Icon
            icon="chevron-left"
            width="30"
            height="30"
            class="icon-arrow"
            iconSet={pawSliderIcons}
          />
        </div>
      );
    }
    const heimdallData = {
      name: this.props.data.allPartnersTitleHeaderBlock.edges[0].node.title,
      subtitle: this.props.data.allPartnersTitleHeaderBlock.edges[0].node.description,
      image: {
        url: this.props.data.allPartnersImageHeaderBlock.edges[0].node.localImage.childImageSharp
          .fluid,
        alt: this.props.data.allPartnersImageHeaderBlock.edges[0].node.alt,
      },
      imageMobile: {
        url: this.props.data.allPartnersImageMobileHeaderBlock.edges[0].node.localImage
          .childImageSharp.fluid,
        alt: this.props.data.allPartnersImageMobileHeaderBlock.edges[0].node.alt,
      },
    };

    const titleData = {
      title: tt('PATROCINADORES OFICIALES', this.props.pageContext.locale),
    };

    const titleData2 = {
      title: tt('COLABORADORES OFICIALES', this.props.pageContext.locale),
    };

    const showImages = (data) =>
      data.map((e) => {
        if (e.node.localImage) {
          return (
            <div className="img-single">
              <a href={e.node.link} target="_blank" rel="noopener noreferrer">
                <Img fluid={e.node.localImage.childImageSharp.fluid} alt={e.node.image_alt} />
              </a>
            </div>
          );
        }
      });

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
      >
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <SEO
            title={this.props.data.allPartnersSeoBlock.edges[0].node._0.title}
            description={this.props.data.allPartnersSeoBlock.edges[0].node._1.description}
            pathname={this.props.pageContext.url}
            lang={this.props.pageContext.locale}
          />
          <Hod
            data={this.props.data.allPartnersRichData.edges[0].node.rich_markup}
            url={this.props.pageContext.url}
            img={this.props.data.allPartnersImageHeaderBlock.edges[0].node.image}
          />
          <div className="general-index">
            <div className="partners-container">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allPartnersBreadcrumb.edges[0].node.name}
              />
              <MediaQuery minWidth={375} maxWidth={900}>
                <TitleH3 data={titleData} />
                <Slider {...settingsUltraLow}>
                  {showImages(this.props.data.allPartnersImages.edges)}
                  {/* {showImages(this.props.data.allColaboratorsSingleImage.edges)} */}
                </Slider>
                <TitleH3 data={titleData2} />
                <Slider {...settingsUltraLow}>
                  {showImages(this.props.data.allColaboratorsImages.edges)}
                </Slider>
              </MediaQuery>
              <MediaQuery minWidth={901}>
                <div className="patrocinadores">
                  <TitleH3 data={titleData} />
                  <div className="img-container">
                    {showImages(this.props.data.allPartnersImages.edges)}
                    {/* {showImages(this.props.data.allColaboratorsSingleImage.edges)} */}
                  </div>
                </div>
                <div className="colaboradores">
                  <TitleH3 data={titleData2} />
                  <div className="img-container">
                    {showImages(this.props.data.allColaboratorsImages.edges)}
                  </div>
                </div>
              </MediaQuery>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Partners;
